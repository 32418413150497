import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import { PrimaryTitle } from "src/components/Typography"

const ContactSuccessPage = () => {
  const [observed, setObserved] = useState(false)

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <Wrapper>
      <Seo title="Thank you!" />
      <PageHeader className={observed ? "-observed" : ""}>
        <PrimaryTitle as="h1">Thank you!</PrimaryTitle>
        <Paragraph>Your message has been sent.</Paragraph>
      </PageHeader>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 115px;
`

const PageHeader = styled.header`
  padding: 180px 0 50px;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    padding: 250px 0 100px;
  }
`

const Paragraph = styled.p`
  text-align: center;
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};
`

export default ContactSuccessPage
